import React, { useState } from "react";
import logo from "../../assets/icons/logo.svg";
import flow from "../../assets/icons/flows.svg";
import apple from "../../assets/icons/apple.svg";
import fb from "../../assets/icons/fb.svg";
import google from "../../assets/icons/google.svg";
import zen from "../../assets/icons/funnel-screen-paywall-relax-mobile.svg";
import AppButton from "../../components/button/AppButton";

function Register(props) {
  const [step, setStep] = useState(1);

  const offers = [
    {
      title: "7 days free",
      subtitle: "Then 14900 ₦/1 year. Only 1241.67 ₦/month",
    },
    {
      title: "3 months, 8700 ₦",
      subtitle: "Only 725 ₦/month.  Billed quarterly",
    },
    {
      title: "1 month, 11090 ₦",
      subtitle: "Billed monthly",
    },
  ];

  return (
    <div className="w-full px">
      <header className="w-full fixed top-0 left-0 z-50 px-4 md:px-0">
        <div className="app-container flex justify-between h-[80px] items-center">
          <div className="w-120px">
            <img src={logo} alt="" />
          </div>
          <div className="w-[70%] md:w-[300px] bg-[#808080] rounded-[10px] h-[10px]">
            <div
              style={{ width: `${(step / 3) * 100}%` }}
              className={`bg-[#ffffff] rounded-[10px] h-full`}
            ></div>
          </div>
          <div className="w-[0px] md:w-[120px]"></div>
        </div>
      </header>
      <div className="w-full px-4 md:px-0">
        <div className="w-full relative z-10 h-[130px] opacity-50 overflow-hidden">
          <img src={flow} alt="" className="w-full" />
        </div>

        {step === 1 && (
          <>
            <section className="w-full relative z-20 -mt-[20px]">
              <h3 className="text-center text-white mx-auto text-[30px] md:text-[40px]">
                Great! Let’s create your
                <div className="block">free Endel account</div>
              </h3>
              <p className="text-center text-[#bfbfbf] mt-[30px] w-full md:w-[600px] text-[16px] md:text-[20px] mx-auto">
                This will only take a few seconds. If you already have one, sign
                in with the method associated with your account.
              </p>
            </section>

            <section className="mt-[40px]">
              <div className="w-full md:w-[500px] mx-auto pt-1">
                <input
                  type="text"
                  placeholder="hi@endel.io"
                  className="h-[50px] w-full border-b-[2px] border-[#808080] bg-transparent focus:outline-none text-white text-[24px]"
                />
                <p className="text-[14px] text-[#808080] mt-2">E-mail</p>

                <AppButton
                  onClick={() => setStep(2)}
                  className="w-full mt-6 border-[2px] border-[#ffffff] hover:bg-[#a5a5a5] hover:border-[#a5a5a5]"
                >
                  About Community
                </AppButton>

                <div className="w-full mt-[40px] border-t-[1px] border-[#262626] flex justify-center">
                  <div className="px-[12px] h-[40px] -mt-[12px] text-[#808080] text-center bg-black">
                    or
                  </div>
                </div>

                <AppButton className="w-full mt-6 hover:bg-[#a5a5a5] hover:border-[#a5a5a5]">
                  <div className="w-fit flex h-full items-center mx-auto">
                    <img src={apple} alt="" className="mr-2" />
                    <span>Continue with Apple</span>
                  </div>
                </AppButton>

                <AppButton className="w-full mt-3 md:mt-6 hover:bg-[#a5a5a5] hover:border-[#a5a5a5]">
                  <div className="w-fit flex h-full items-center mx-auto">
                    <img src={fb} alt="" className="mr-2" />
                    <span>Continue with Facebook</span>
                  </div>
                </AppButton>
                <p className="text-center text-[#bfbfbf] mt-3 text-[12px] pb-6">
                  By continuing, you agree to Privacy Policy and Terms &
                  Conditions
                </p>
              </div>
            </section>
          </>
        )}

        {step === 2 && (
          <>
            <div className="flex flex-wrap justify-between app-container mx-auto">
              <div className="w-full md:w-1/2 pt-6">
                <img
                  src={zen}
                  alt=""
                  className="w-full md:w-[120%] mt-[50px]"
                />
              </div>
              <div className="w-full md:w-1/2 pb-6">
                <div className="w-full md:w-[500px] mx-auto">
                  <h3 className="text-center text-white mx-auto text-[30px]">
                    Try your personal
                    <div className="block">relaxation sounds for free</div>
                  </h3>
                  <p className="text-center text-[#bfbfbf] mt-[30px] w-full text-[20px] mx-auto">
                    Make the most of your days. Get a week of Endel Premium,
                    free of charge.
                  </p>
                </div>

                <div className="w-full md:w-[450px] mx-auto px-4">
                  {offers.map((itm, i) => (
                    <div
                      key={i}
                      className="cursor-pointer w-full border-[1px] hover:border-[#ffffff] border-[#000000] bg-[#191919] rounded-[12px] flex justify-between items-center mt-5 px-[20px] h-[70px]"
                    >
                      <div className="pr-4">
                        <p className="text-[16px] text-[#ffffff] font-medium">
                          {itm.title}
                        </p>
                        <p className="text-[12px] md:text-[14px] text-[#bfbfbf]">
                          {itm.subtitle}
                        </p>
                      </div>
                      <input type="checkbox" name="" id="" />
                    </div>
                  ))}

                  <AppButton
                    onClick={() => setStep(3)}
                    className="w-full mt-6 border-[2px] border-[#ffffff] hover:bg-[#a5a5a5] hover:border-[#a5a5a5]"
                  >
                    Continue
                  </AppButton>

                  <p className="text-[12px] mx-auto px-[50px] text-[#bfbfbf] mt-3 text-center">
                    Cancel anytime. Secured by Stripe 30-days Money-back
                    guarantee
                  </p>
                </div>
              </div>
            </div>
          </>
        )}

        {step === 3 && (
          <>
            <section className="w-full relative z-20 -mt-[20px]">
              <h3 className="text-center text-white mx-auto text-[30px] md:text-[40px]">
                Enter payment details
              </h3>
              <p className="text-center text-[#bfbfbf] mt-[12px] md:mt-[30px] w-full md:w-[600px] text-[16px] md:text-[20px] px-[40px] mx-auto">
                Enter your card details below or follow the options for Apple
                Pay and Google Pay.
              </p>
            </section>

            <section className="w-full md:w-[550px] mx-auto mt-[40px] bg-[#191919] rounded-[20px] p-[24px] flex flex-wrap justify-between">
              <div className="w-full mb-4">
                <input
                  type="text"
                  placeholder="1234 1234 1234 1234"
                  className="h-[50px] w-full border-b-[2px] border-[#808080] bg-transparent focus:outline-none text-white text-[20px]"
                />
                <p className="text-[14px] text-[#808080] mt-2">Card number</p>
              </div>
              <div className="w-1/2 pr-4">
                <input
                  type="text"
                  placeholder="MM/YY"
                  className="h-[50px] w-full border-b-[2px] border-[#808080] bg-transparent focus:outline-none text-white text-[20px]"
                />
                <p className="text-[14px] text-[#808080] mt-2">Expiry</p>
              </div>
              <div className="w-1/2 pl-4">
                <input
                  type="text"
                  placeholder="CVC"
                  className="h-[50px] w-full border-b-[2px] border-[#808080] bg-transparent focus:outline-none text-white text-[20px]"
                />
                <p className="text-[14px] text-[#808080] mt-2">CVC</p>
              </div>

              <div className="w-full mt-4">
                <AppButton className="w-full border-[2px] border-[#ffffff] hover:bg-[#a5a5a5] hover:border-[#a5a5a5]">
                  Pay by Card
                </AppButton>
                <AppButton className="w-full mt-4 hover:bg-[#a5a5a5] hover:border-[#a5a5a5]">
                  <div className="w-fit flex h-full items-center mx-auto">
                    <img src={google} alt="" className="mr-2" />
                    <span>Google pay</span>
                  </div>
                </AppButton>
              </div>
            </section>
          </>
        )}
      </div>
    </div>
  );
}

export default Register;
