import React from "react";
import logo from "../../assets/images/logo.png";
import secure from "../../assets/images/secure.jpg";
import iphone from "../../assets/images/happy.jpg";
import protect from "../../assets/images/protec.jpeg";
import "./Home.css";

function Home(props) {
  const industries = [
    "Email Compromise",
    "Phishing Attacks",
    "Identity Theft",
    "Behavioral Patterns",
    "Anti Money Laundering",
  ];
  return (
    <div className="w-full h-[100vh] overflow-scroll bg-black">
      <header className="w-full h-[80px] flex items-center justify-between app-container sticky top-0 bg-black">
        <img src={logo} className="w-[120px]" alt="" />

        <button className="border-white border-[1px] px-[20px] h-[50px] text-white">
          ↖ Get Access
        </button>
      </header>
      <section className="w-full app-container pb-[60px] border-b-[2px] border-white">
      <img
            src={protect}
            alt=""
            className="w-full h-[200px] md:h-[730px] mx-auto pb-[30px]"
          />
        {/* <video
          src="https://www.palantir.com/assets/xrfr7uokpv1b/6wPXo22rIy9sWfuTM9ALSe/6c00236051d5e314adb17d399ad502c0/Hydrate_Ontology_General_V3.mp4"
          autoplay=""
          loop=""
          playsinline=""
          controls=""
          className="w-full h-[200px] md:h-[740px] mx-auto"
        ></video> */}

        <div className="w-full flex justify-end">
          <div className="w-[300px] pb-4 border-b-[1px] border-white font-bold text-white">
            Stay Safe With Roe
          </div>
        </div>
      </section>

      <section className="app-container pt-[30px] pb-[60px] border-b-[2px] border-white mb-[10px]">
        <h1 className="w-full text-[40px] md:text-[80px] alliance-2 font-bold text-white">
          What is Roe?
        </h1>

        <div className="w-full flex flex-wrap py-[30px] px-4 md:px-0">
          <div className="w-full md:w-1/2 px-4 md:px-0 pr-0 md:pr-[120px] mb-4">
            <img src={secure} alt="" />
          </div>

          <div className="w-full md:w-1/2 px-4 md:px-0">
            <h3 className="text-[20px] md:text-[34px] font-bold mb-[20px] leading-7 md:leading-9 text-white">
              Safeguard Your Financial Dealings with Cutting-Edge AI Fraud
              Prevention
            </h3>
            <p className="text-[16px] text-white">
              In today's digital age, ensuring the security of financial
              transactions is paramount. Introducing our cutting-edge
              Customer-Facing AI Fraud Prevention System, a robust solution
              designed to protect your financial well-being and safeguard
              transactions from fraudulent activities.
            </p>
          </div>
        </div>
      </section>

      <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-white mb-[10px] px-4 md:px-0">
        <div className="w-full md:w-1/2">
          <h3 className="alliance-2 text-[30px] md:text-[50px] mb-[50px] font-bold text-white">
            Get Started Today →
          </h3>

          <img
            src={iphone}
            alt=""
            className="w-[90%] mx-auto"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h4 className="text-[20px] md:text-[34px] leading-6 md:leading-10 mb-[60px] mt-[20px] md:mt-0 text-white">
            Don't compromise on security. Invest in the future of fraud
            prevention with our cutting-edge AI-powered solution.
          </h4>

          <div className="md:pl-[100px]">
            <div className="border-t-[2px] border-white py-[30px] text-[16px]">
              <p className="font-semibold text-white">
                Advanced AI and Machine Learning Algorithms
              </p>
              <p className="mt-[20px] text-white">
                Our system is powered by advanced artificial intelligence (AI)
                and machine learning algorithms, providing unparalleled accuracy
                and efficiency in fraud detection and prevention. By
                continuously learning and adapting, it stays ahead of evolving
                fraud tactics to keep your transactions secure.
              </p>
            </div>
            <div className="border-t-[2px] border-white py-[30px] text-[16px]">
              <p className="font-semibold text-white">
                Real-Time Detection and Prevention.
              </p>
              <p className="mt-[20px] text-white">
                With real-time monitoring capabilities, our AI Fraud Prevention
                System acts as your vigilant partner, instantly detecting and
                preventing fraudulent activities as they occur. This proactive
                approach minimizes risks and ensures a seamless and secure
                transaction experience for your customers.
              </p>
            </div>
            <div className="border-t-[2px] border-white py-[30px] text-[16px]">
              <p className="font-semibold text-white">
                Comprehensive Protection.
              </p>
              <p className="mt-[20px] text-white">
                From identity theft to payment fraud, our system offers
                comprehensive protection across various types of fraudulent
                activities. Whether it's detecting suspicious patterns or
                flagging unauthorized transactions, you can trust our solution
                to keep your financial operations secure.
              </p>
            </div>
            <div className="border-t-[2px] border-white py-[30px] text-[16px]">
              <p className="font-semibold text-white">
                Seamless Integration and User-Friendly Interface.
              </p>
              <p className="mt-[20px] text-white">
                Integrating our AI Fraud Prevention System into your existing
                infrastructure is seamless, ensuring minimal disruption to your
                business operations. Plus, its user-friendly interface makes it
                easy for your team to manage and monitor fraud prevention
                efforts effectively
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-white mb-[10px] px-4 md:px-0">
        <div className="w-full md:w-1/2">
          <h3 className="alliance-2 text-[30px] md:text-[50px] mb-[50px] font-bold text-white">
            Partner in Financial Security
          </h3>
        </div>
        <div className="w-full md:w-1/2">
          <div className="text-[16px] md:text-[20px] text-[#767676] mb-[30px] text-white">
            <p>
              Consider our Customer-Facing AI Fraud Prevention System as your
              dedicated partner in financial security. With its advanced
              capabilities and real-time monitoring, you can focus on growing
              your business with confidence, knowing that your transactions are
              protected.
            </p>
            {/* <p className="mt-6">
              Today, some of the world’s most important institutions use Foundry
              to build safer cars, secure global supply chains, accelerate
              cancer research, and more.
            </p> */}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[20px]">
            {industries.map((industry) => (
              <div className="w-full">
                <div className="flex justify-between text-[20px] border-t-[1px] border-white py-5 text-white">
                  <span>{industry}</span>
                  <span>→</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="app-container py-[80px] pb-[60px] border-b-[2px] border-white mb-[10px] px-4 md:px-0">
        <h1
          className="text-[40px] md:text-[100px] font-bold text-white"
          style={{ lineHeight: "110%" }}
        >
          Why Choose Us?
        </h1>

        <div className="w-full flex flex-wrap mt-[30px] md:mt-[80px] mb-[90px]">
          <div className="w-full md:w-1/3">
            <p className="text-[30px] font-bold text-white">Our Key Features</p>
          </div>
          <div className="w-full md:w-2/3">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-x-4">
              <div className="w-full">
                {/* <img
                  src="https://www.palantir.com/assets/xrfr7uokpv1b/vQPFCjCIpddZEeuLWE99I/00c31f551fcd15699c4d06e33d052601/My_project__7_.png?quality=70&width=700&height=467&fit=crop"
                  alt=""
                  className="mb-4"
                /> */}

                <p className="text-white text-[16px] mb-[20px] md:mb-0">
                  Detect fraudulent activities as they occur, minimizing risks
                  and losses.
                </p>
              </div>
              <div className="w-full">
                {/* <img
                  src="https://www.palantir.com/assets/xrfr7uokpv1b/vQPFCjCIpddZEeuLWE99I/00c31f551fcd15699c4d06e33d052601/My_project__7_.png?quality=70&width=700&height=467&fit=crop"
                  alt=""
                  className="mb-4"
                /> */}

                <p className="text-white text-[16px] mb-[20px] md:mb-0">
                  Receive instant alerts and notifications for suspicious
                  transactions, allowing for swift action
                </p>
              </div>
              <div className="w-full">
                {/* <img
                  src="https://www.palantir.com/assets/xrfr7uokpv1b/vQPFCjCIpddZEeuLWE99I/00c31f551fcd15699c4d06e33d052601/My_project__7_.png?quality=70&width=700&height=467&fit=crop"
                  alt=""
                  className="mb-4"
                /> */}

                <p className="text-white text-[16px] mb-[20px] md:mb-0">
                  Safeguard transactions across multiple channels and payment
                  methods, ensuring comprehensive security.
                </p>
              </div>
              <div className="w-full">
                {/* <img
                  src="https://www.palantir.com/assets/xrfr7uokpv1b/vQPFCjCIpddZEeuLWE99I/00c31f551fcd15699c4d06e33d052601/My_project__7_.png?quality=70&width=700&height=467&fit=crop"
                  alt=""
                  className="mb-4"
                /> */}

                <p className="text-white text-[16px] mb-[20px] md:mb-0">
                  Utilize cutting-edge AI and machine learning algorithms for
                  precise and proactive fraud prevention.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
